@font-face {
    font-family: 'Gotham';
    src: local('Gotham Book'), local('Gotham-Book'),
    url('./static/fonts/GothamRegular/Gotham-Book.woff2') format('woff2'),
    url('./static/fonts/GothamRegular/Gotham-Book.woff') format('woff'),
    url('./static/fonts/GothamRegular/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold'), local('Gotham-Bold'),
    url('./static/fonts/GothamBold/Gotham-Bold.woff2') format('woff2'),
    url('./static/fonts/GothamBold/Gotham-Bold.woff') format('woff'),
    url('./static/fonts/GothamBold/Gotham-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}